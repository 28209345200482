import React, { useState, useEffect } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import LoadingScreen from './Components/LoadingScreen';
import * as UnityContextUtils from './Utils/UnityContextUtils';

const unityContext = new UnityContext({
  loaderUrl: process.env.NODE_ENV === "development" ? "DevBuild/Build/DevBuild.loader.js" : "ProdBuild/Build/ProdBuild.loader.js",
  dataUrl: process.env.NODE_ENV === "development" ? "DevBuild/Build/DevBuild.data" : "ProdBuild/Build/ProdBuild.data.br",
  frameworkUrl: process.env.NODE_ENV === "development" ? "DevBuild/Build/DevBuild.framework.js" : "ProdBuild/Build/ProdBuild.framework.js.br",
  codeUrl: process.env.NODE_ENV === "development" ? "DevBuild/Build/DevBuild.wasm" : "ProdBuild/Build/ProdBuild.wasm.br",
  streamingAssetsUrl: process.env.NODE_ENV === "development" ? "DevBuild/StreamingAssets" : "ProdBuild/StreamingAssets",
  productName: "Plethora",
  productVersion: "0.1.0",
  companyName: "IC Studio",
});


function App() {
  const [progression, setProgression] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(function () {
    unityContext.on("progress", function (progression) {
      setProgression(progression);
    });
  }, []);

  useEffect(function () {
    unityContext.on("loaded", function () {
      setIsLoaded(true);
    });
  }, []);

  UnityContextUtils.AddUnityListeners(unityContext);


  //To Test ICRC Payment Service

  // const to = "szlep-363px-awxtb-nxd6a-ofo7v-5cgxb-56rlg-gyvu7-zl6ps-jdf5k-vae";
  // const amt = BigInt(20);
  // const paymentType = "offer";
  // const paymentMetadata = "Pastry-Variable-Offer";
  // const tokenCanisterId = "mxzaz-hqaaa-aaaar-qaada-cai";
  // const paymentCanister = "oi7tv-6iaaa-aaaal-abv7q-cai";
  // const cbIndex = Number(0);

  // const handleUnityEvent = () => {
  //   unityContext.dispatchEvent('MakeIcrcPayment', cbIndex, 'INFINITY', to, BigInt(amt), paymentType, paymentMetadata, String(tokenCanisterId), String(paymentCanister));
  // };


  //To Test Burn feature
  
  // const index = 34;
  // const id = "aks3o-oiaaa-aaaal-abu7q-cai";
  // const cbIndex = Number(0);

  // const handleUnityEvent = () => {
  //   unityContext.dispatchEvent('BurnNft', cbIndex, 'PLUG', Number(index), String(id));
  // };
  
  
  

  return (
    <div>
      {!isLoaded && <LoadingScreen value={progression * 100} />}
    <Unity unityContext={unityContext} devicePixelRatio={1}
           style={{
      height: "100%",
      width: "100%",
      position: "absolute" }}
    />
      {/* <button
        style={{ backgroundColor: "transparent", cursor: 'pointer', marginTop: 20, marginBottom: 20, width: 150, height: 30 }}
        className=""
        onClick={handleUnityEvent}>
        Mint!
      </button> */}
    </div>
  );
}

export default App;