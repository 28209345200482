import { getAllUserNFTs, getNFTActor } from "@psychedelic/dab-js";
import { Principal } from "@dfinity/principal";

export async function MakeIcrcPaymentPlug(cbIndex, unityContext, _to, _amt, _paymentType, _paymentMetadata, _tokenCanisterId, paymentCanisterId) {
  let data = {};
  data.cbIndex = cbIndex;

  try {
    const whitelist = [paymentCanisterId];

    if (typeof window.ic === 'undefined' || typeof window.ic.plug === 'undefined') {
      throw new Error("We cannot detect a Plug Wallet in your chrome browser extensions");
    }

    const connected = await window.ic.plug.isConnected();
    if (!connected) {
      await window.ic.plug.requestConnect({
        whitelist,
      });
    }

    const sessionData = window.ic.plug.sessionManager.sessionData;
    const _from = sessionData.principalId;
    // console.log(_from);
    const paymentidlFactory = ({ IDL }) => {
      const HttpHeader = IDL.Record({ 'value': IDL.Text, 'name': IDL.Text });
      const CanisterHttpResponsePayload = IDL.Record({
        'status': IDL.Nat,
        'body': IDL.Vec(IDL.Nat8),
        'headers': IDL.Vec(HttpHeader),
      });
      const TransformArgs = IDL.Record({
        'context': IDL.Vec(IDL.Nat8),
        'response': CanisterHttpResponsePayload,
      });
      const Response = IDL.Variant({
        'Err': IDL.Text,
        'Success': IDL.Opt(IDL.Text),
      });
      const TxIndex = IDL.Nat;
      const Balance = IDL.Nat;
      return IDL.Service({
        'cycleBalance': IDL.Func([], [IDL.Nat], ['query']),
        'getAID': IDL.Func([IDL.Text], [IDL.Text], []),
        'getCaller': IDL.Func([], [IDL.Text], []),
        'transform': IDL.Func(
          [TransformArgs],
          [CanisterHttpResponsePayload],
          ['query'],
        ),
        'update_assets': IDL.Func(
          [IDL.Nat64, IDL.Text, IDL.Text, IDL.Nat64, IDL.Text, IDL.Text],
          [Response],
          [],
        ),
        'update_assets_icrc': IDL.Func(
          [TxIndex, IDL.Text, IDL.Text, Balance, IDL.Text, IDL.Text, IDL.Text],
          [Response],
          [],
        ),
      });
    };
    const idlFactory = ({ IDL }) => {
      const Balance = IDL.Nat;
      const Timestamp = IDL.Nat64;
      const AdvancedSettings = IDL.Record({
        'permitted_drift': Timestamp,
        'burned_tokens': Balance,
        'transaction_window': Timestamp,
      });
      const Subaccount = IDL.Vec(IDL.Nat8);
      const Account = IDL.Record({
        'owner': IDL.Principal,
        'subaccount': IDL.Opt(Subaccount),
      });
      const TokenInitArgs = IDL.Record({
        'fee': Balance,
        'advanced_settings': IDL.Opt(AdvancedSettings),
        'decimals': IDL.Nat8,
        'minting_account': IDL.Opt(Account),
        'name': IDL.Text,
        'initial_balances': IDL.Vec(IDL.Tuple(Account, Balance)),
        'min_burn_amount': Balance,
        'max_supply': Balance,
        'symbol': IDL.Text,
      });
      const BurnArgs = IDL.Record({
        'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
        'from_subaccount': IDL.Opt(Subaccount),
        'created_at_time': IDL.Opt(IDL.Nat64),
        'amount': Balance,
      });
      const TxIndex = IDL.Nat;
      const TransferError = IDL.Variant({
        'GenericError': IDL.Record({
          'message': IDL.Text,
          'error_code': IDL.Nat,
        }),
        'TemporarilyUnavailable': IDL.Null,
        'BadBurn': IDL.Record({ 'min_burn_amount': Balance }),
        'Duplicate': IDL.Record({ 'duplicate_of': TxIndex }),
        'BadFee': IDL.Record({ 'expected_fee': Balance }),
        'CreatedInFuture': IDL.Record({ 'ledger_time': Timestamp }),
        'TooOld': IDL.Null,
        'InsufficientFunds': IDL.Record({ 'balance': Balance }),
      });
      const TransferResult = IDL.Variant({ 'Ok': TxIndex, 'Err': TransferError });
      const TxIndex__1 = IDL.Nat;
      const Burn = IDL.Record({
        'from': Account,
        'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
        'created_at_time': IDL.Opt(IDL.Nat64),
        'amount': Balance,
      });
      const Mint__1 = IDL.Record({
        'to': Account,
        'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
        'created_at_time': IDL.Opt(IDL.Nat64),
        'amount': Balance,
      });
      const Transfer = IDL.Record({
        'to': Account,
        'fee': IDL.Opt(Balance),
        'from': Account,
        'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
        'created_at_time': IDL.Opt(IDL.Nat64),
        'amount': Balance,
      });
      const Transaction__1 = IDL.Record({
        'burn': IDL.Opt(Burn),
        'kind': IDL.Text,
        'mint': IDL.Opt(Mint__1),
        'timestamp': Timestamp,
        'index': TxIndex,
        'transfer': IDL.Opt(Transfer),
      });
      const GetTransactionsRequest = IDL.Record({
        'start': TxIndex,
        'length': IDL.Nat,
      });
      const Transaction = IDL.Record({
        'burn': IDL.Opt(Burn),
        'kind': IDL.Text,
        'mint': IDL.Opt(Mint__1),
        'timestamp': Timestamp,
        'index': TxIndex,
        'transfer': IDL.Opt(Transfer),
      });
      const GetTransactionsRequest__1 = IDL.Record({
        'start': TxIndex,
        'length': IDL.Nat,
      });
      const TransactionRange = IDL.Record({
        'transactions': IDL.Vec(Transaction),
      });
      const QueryArchiveFn = IDL.Func(
        [GetTransactionsRequest__1],
        [TransactionRange],
        ['query'],
      );
      const ArchivedTransaction = IDL.Record({
        'callback': QueryArchiveFn,
        'start': TxIndex,
        'length': IDL.Nat,
      });
      const GetTransactionsResponse = IDL.Record({
        'first_index': TxIndex,
        'log_length': IDL.Nat,
        'transactions': IDL.Vec(Transaction),
        'archived_transactions': IDL.Vec(ArchivedTransaction),
      });
      const Account__1 = IDL.Record({
        'owner': IDL.Principal,
        'subaccount': IDL.Opt(Subaccount),
      });
      const Balance__1 = IDL.Nat;
      const Value = IDL.Variant({
        'Int': IDL.Int,
        'Nat': IDL.Nat,
        'Blob': IDL.Vec(IDL.Nat8),
        'Text': IDL.Text,
      });
      const MetaDatum = IDL.Tuple(IDL.Text, Value);
      const SupportedStandard = IDL.Record({ 'url': IDL.Text, 'name': IDL.Text });
      const TransferArgs = IDL.Record({
        'to': Account,
        'fee': IDL.Opt(Balance),
        'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
        'from_subaccount': IDL.Opt(Subaccount),
        'created_at_time': IDL.Opt(IDL.Nat64),
        'amount': Balance,
      });
      const Mint = IDL.Record({
        'to': Account,
        'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
        'created_at_time': IDL.Opt(IDL.Nat64),
        'amount': Balance,
      });
      const Token = IDL.Service({
        'burn': IDL.Func([BurnArgs], [TransferResult], []),
        'deposit_cycles': IDL.Func([], [], []),
        'get_transaction': IDL.Func([TxIndex__1], [IDL.Opt(Transaction__1)], []),
        'get_transactions': IDL.Func(
          [GetTransactionsRequest],
          [GetTransactionsResponse],
          ['query'],
        ),
        'icrc1_balance_of': IDL.Func([Account__1], [Balance__1], ['query']),
        'icrc1_decimals': IDL.Func([], [IDL.Nat8], ['query']),
        'icrc1_fee': IDL.Func([], [Balance__1], ['query']),
        'icrc1_metadata': IDL.Func([], [IDL.Vec(MetaDatum)], ['query']),
        'icrc1_minting_account': IDL.Func([], [IDL.Opt(Account__1)], ['query']),
        'icrc1_name': IDL.Func([], [IDL.Text], ['query']),
        'icrc1_supported_standards': IDL.Func(
          [],
          [IDL.Vec(SupportedStandard)],
          ['query'],
        ),
        'icrc1_symbol': IDL.Func([], [IDL.Text], ['query']),
        'icrc1_total_supply': IDL.Func([], [Balance__1], ['query']),
        'icrc1_transfer': IDL.Func([TransferArgs], [TransferResult], []),
        'mint': IDL.Func([Mint], [TransferResult], []),
      });
      return Token;
    };

    const token = await window.ic.plug.createActor({
      canisterId: _tokenCanisterId,
      interfaceFactory: idlFactory,
    });

    // Transfer tokens to desired address
    const _req = {
      to: {
        // owner : Principal.fromText(_to),
        owner: Principal.from(_to),
        subaccount: [],
      },
      fee: [BigInt(10)],
      memo: [],
      from_subaccount: [],
      created_at_time: [],
      amount: BigInt(_amt),
    };
    const result = await token.icrc1_transfer(_req);
    if (result.Ok === undefined) {
      throw result.Err;
    } else {
      //asset update in DB
      const actor = await window.ic.plug.createActor({
        canisterId: paymentCanisterId,
        interfaceFactory: paymentidlFactory,
      });
      const response = await actor.update_assets_icrc(BigInt(result.Ok), String(_to), String(_from), BigInt(_amt), String(_paymentType), String(_paymentMetadata), String(_tokenCanisterId));
      console.log('Purchase Result: ', response);

      if (response.Err != null) {
        throw new Error("Error in payment canister. Please contact us in Discord to troubleshoot the issue. Error=" + response.Err);
      }

      data.result = response.Success[0];
      unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
    }
  } catch (e) {
    console.error(e);
    data.error = e.message;
    if (e.InsufficientFunds) {
      data.error = "Insufficient Funds";
    }
    unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
  }
}

export async function MakePaymentPlug(cbIndex, unityContext, _to, _amt, _paymentType, _paymentMetadata, paymentCanisterId) {
  let data = {};
  data.cbIndex = cbIndex;

  try {
    const whitelist = [paymentCanisterId];

    if (typeof window.ic === 'undefined' || typeof window.ic.plug === 'undefined') {
      throw new Error("We cannot detect a Plug Wallet in your chrome browser extensions");
    }

    const connected = await window.ic.plug.isConnected();
    if (!connected) {
      await window.ic.plug.requestConnect({
        whitelist,
      });
    }

    const sessionData = window.ic.plug.sessionManager.sessionData;
    const _from = sessionData.principalId;
    const idlFactory = ({ IDL }) => {
      const HttpHeader = IDL.Record({ 'value': IDL.Text, 'name': IDL.Text });
      const CanisterHttpResponsePayload = IDL.Record({
        'status': IDL.Nat,
        'body': IDL.Vec(IDL.Nat8),
        'headers': IDL.Vec(HttpHeader),
      });
      const TransformArgs = IDL.Record({
        'context': IDL.Vec(IDL.Nat8),
        'response': CanisterHttpResponsePayload,
      });
      const Response = IDL.Variant({
        'Err': IDL.Text,
        'Success': IDL.Opt(IDL.Text),
      });
      return IDL.Service({
        'cycleBalance': IDL.Func([], [IDL.Nat], ['query']),
        'getAID': IDL.Func([IDL.Text], [IDL.Text], []),
        'getCaller': IDL.Func([], [IDL.Text], []),
        'transform': IDL.Func(
          [TransformArgs],
          [CanisterHttpResponsePayload],
          ['query'],
        ),
        'update_assets': IDL.Func(
          [IDL.Nat64, IDL.Text, IDL.Text, IDL.Nat64, IDL.Text, IDL.Text],
          [Response],
          [],
        ),
      });
    };

    const actor = await window.ic.plug.createActor({
      canisterId: paymentCanisterId,
      interfaceFactory: idlFactory,
    });

    // Transfer amount to desired address
    const params = {
      to: _to,
      amount: Number(_amt),
    };
    const result = await window.ic.plug.requestTransfer(params);
    console.log(result);
    // Verify transaction, ping server to update assets of purchaser 
    const response = await actor.update_assets(BigInt(result.height), String(_to), String(_from), BigInt(_amt), String(_paymentType), String(_paymentMetadata));

    console.log('Purchase Result: ', response);

    if (response.Err != null) {
      throw new Error("Error in payment canister. Please contact us in Discord to troubleshoot the issue. Error=" + response.Err);
    }

    data.result = response.Success[0];
    unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
  } catch (e) {
    console.error(e);
    data.error = e.message;
    if (e.InsufficientFunds) {
      data.error = "Insufficient Funds";
    }
    unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
  }
}

export async function BurnNftPlug(cbIndex, unityContext, nftIndex, collectionCanisterId, deployerCanisterId) {
  let data = {};
  data.cbIndex = cbIndex;

/*  console.log("nftIndex=" + nftIndex);
  console.log("collectionCanisterid=" + collectionCanisterId);
  console.log("deployerCanisterid=" + deployerCanisterId);*/

  try {
    const whitelist = [deployerCanisterId, collectionCanisterId];

    if (typeof window.ic === 'undefined' || typeof window.ic.plug === 'undefined') {
      throw new Error("We cannot detect a Plug Wallet in your chrome browser extensions");
    }

    const connected = await window.ic.plug.isConnected();
    if (!connected) {
      await window.ic.plug.requestConnect({
        whitelist,
      });
    }

    const idlFactory = ({ IDL }) => {
      const TokenIndex = IDL.Nat32;
      const AccountIdentifier = IDL.Text;
      const TokenIdentifier__1 = IDL.Text;
      const CommonError = IDL.Variant({
        'InvalidToken': TokenIdentifier__1,
        'Other': IDL.Text,
      });
      const Result_1 = IDL.Variant({
        'ok': IDL.Opt(IDL.Text),
        'err': CommonError,
      });
      const AssetHandle = IDL.Text;
      const Result = IDL.Variant({ 'ok': IDL.Null, 'err': CommonError });
      const TokenIdentifier = IDL.Text;
      const ICHttpHeader = IDL.Record({ 'value': IDL.Text, 'name': IDL.Text });
      const ICCanisterHttpResponsePayload = IDL.Record({
        'status': IDL.Nat,
        'body': IDL.Vec(IDL.Nat8),
        'headers': IDL.Vec(ICHttpHeader),
      });
      const ICTransformArgs = IDL.Record({
        'context': IDL.Vec(IDL.Nat8),
        'response': ICCanisterHttpResponsePayload,
      });
      return IDL.Service({
        'airdrop_to_addresses': IDL.Func(
          [IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Bool, IDL.Int],
          [IDL.Vec(TokenIndex)],
          [],
        ),
        'batch_mint_to_address': IDL.Func(
          [
            IDL.Text,
            AccountIdentifier,
            IDL.Text,
            IDL.Text,
            IDL.Text,
            IDL.Nat32,
            IDL.Int,
          ],
          [IDL.Vec(TokenIndex)],
          [],
        ),
        'burnNft': IDL.Func(
          [IDL.Text, TokenIndex, AccountIdentifier],
          [Result_1],
          [],
        ),
        'burnNfts': IDL.Func(
          [IDL.Text, TokenIndex, TokenIndex, AssetHandle],
          [],
          [],
        ),
        'clear_collection_registry': IDL.Func([], [], []),
        'create_collection': IDL.Func(
          [IDL.Text, IDL.Text, IDL.Text, IDL.Nat64],
          [IDL.Text],
          [],
        ),
        'cycleBalance': IDL.Func([], [IDL.Nat], ['query']),
        'external_burn': IDL.Func([IDL.Text, TokenIndex], [Result], []),
        'getAID': IDL.Func([], [AccountIdentifier], []),
        'getCollectionMetadata': IDL.Func([IDL.Text], [IDL.Text, IDL.Text], []),
        'getCollections': IDL.Func([], [IDL.Vec(IDL.Text)], ['query']),
        'getOwner': IDL.Func([IDL.Text], [IDL.Text], ['query']),
        'getRegistry': IDL.Func([IDL.Text], [IDL.Vec(IDL.Text)], []),
        'getTokenIdentifier': IDL.Func(
          [IDL.Text, TokenIndex],
          [TokenIdentifier],
          [],
        ),
        'getTokenMetadata': IDL.Func([IDL.Text, TokenIndex], [IDL.Text], []),
        'getTokenUrl': IDL.Func([IDL.Text, TokenIndex], [IDL.Text], []),
        'getUserNfts': IDL.Func(
          [IDL.Text, IDL.Text],
          [IDL.Vec(IDL.Tuple(TokenIndex, IDL.Text))],
          [],
        ),
        'kill_cron': IDL.Func([], [], []),
        'setMinter': IDL.Func([IDL.Text, IDL.Text], [], []),
        'transform': IDL.Func(
          [ICTransformArgs],
          [ICCanisterHttpResponsePayload],
          ['query'],
        ),
        'uploadAsset': IDL.Func(
          [IDL.Text, AssetHandle, IDL.Text, IDL.Text],
          [],
          [],
        ),
        'wallet_receive': IDL.Func([], [IDL.Nat], []),
      });
    };

    const actor = await window.ic.plug.createActor({
      canisterId: deployerCanisterId,
      interfaceFactory: idlFactory,
    });
    //Burn Nft and Notify Server
    const _from = await actor.getAID();
    console.log(_from);
    console.log(collectionCanisterId);
    console.log(nftIndex);

    const response = await actor.burnNft(String(collectionCanisterId), Number(nftIndex), String(_from));
    console.log('Burn Result: ', response);

    if (response.err != null) {
      throw new Error("Error while calling burn in nft canister. Please contact us in Discord to troubleshoot the issue. Error=" + response.err);
    }

    data.result = response.ok[0];
    unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
  } catch (e) {
    console.error(e);
    data.error = e.message;
    unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
  }
}

export async function GetServerAuthToken(cbIndex, unityContext) {
  let data = {};
  data.cbIndex = cbIndex;

  try {
    const authCanisterId = 'zxrmm-bqaaa-aaaai-abn6q-cai'
    const whitelist = [authCanisterId];

    if (typeof window.ic === 'undefined' || typeof window.ic.plug === 'undefined') {
      throw new Error("We cannot detect a Plug Wallet in your chrome browser extensions");
    }

    // Initialise Agent, expects no return value
    await window.ic.plug.requestConnect({
      whitelist,
    });

    const idlFactory = ({ IDL }) => {
      return IDL.Service({
        'get_auth_token': IDL.Func([], [IDL.Text], [])
      });
    };

    const actor = await window.ic.plug.createActor({
      canisterId: authCanisterId,
      interfaceFactory: idlFactory,
    });

    const token = await actor.get_auth_token();
    //console.log('Auth Token: ', token);
    data.result = token;
    unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
  } catch (e) {
    console.error(e);
    data.error = e.message;
    unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
  }
}