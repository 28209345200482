import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import logo from '../logo.png';

function LoadingScreen(props) {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "700px"
        }}>
            <img src={logo} alt="Logo"/>
            <CircularProgress style={{position:"absolute", width: '600px', height: '600px'}} color="inherit" variant="determinate" value={props.value}></CircularProgress>
        </div>
    );
}

export default LoadingScreen;