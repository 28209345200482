import * as NfidUtils from "./NfidUtils";
import * as InfinityUtils from "./InfinityUtils";
import * as StoicUtils from "./StoicUtils";
import * as PlugUtils from "./PlugUtils";
import * as MetamaskUtils from "./MetamaskUtils";

let fullscreen = false;

export function AddUnityListeners(unityContext) {
  unityContext.on(
    "MakeIcrcPayment",
    async function (cbIndex, walletType, _to, _amt, _paymentType, _paymentMetadata, _tokenCanisterId, _paymentCanisterId) {
      const amt = Math.trunc(Number(_amt));
      switch (walletType) {
        case "NFID":
          await NfidUtils.MakeIcrcPaymentNfid(
            cbIndex,
            unityContext,
            _to,
            amt,
            _paymentType,
            _paymentMetadata,
            _tokenCanisterId,
              _paymentCanisterId
          );
          break;
        case "INFINITY":
          await InfinityUtils.MakeIcrcPaymentInfinity(
            cbIndex,
            unityContext,
            _to,
            amt,
            _paymentType,
            _paymentMetadata,
            _tokenCanisterId,
              _paymentCanisterId
          );
          break;
        case "STOIC":
          await StoicUtils.MakeIcrcPaymentStoic(
            cbIndex,
            unityContext,
            _to,
            amt,
            _paymentType,
            _paymentMetadata,
            _tokenCanisterId,
              _paymentCanisterId
          );
          break;
        case "PLUG":
          await PlugUtils.MakeIcrcPaymentPlug(
            cbIndex,
            unityContext,
            _to,
            amt,
            _paymentType,
            _paymentMetadata,
            _tokenCanisterId,
              _paymentCanisterId
          );
          break;
        default:
          let data = {};
          data.cbIndex = cbIndex;
          data.error =
            "Please open the Pause Menu by pressing ESC key and go to the top right corner and press the button that says CONNECTED. Then press the Logout button in the top right. Then Log In to the game again with your wallet and try this again";
          unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
      }
    }
  );

  unityContext.on(
    "MakePayment",
    async function (cbIndex, walletType, _to, _amt, _paymentType, _paymentMetadata, _paymentCanisterId) {
      const amt = Math.trunc(Number(_amt));
      console.log(`amount as string ${_amt}, amount as Number ${amt}`);

      switch (walletType) {
        case "NFID":
          await NfidUtils.MakePaymentNfid(
            cbIndex,
            unityContext,
            _to,
            amt,
            _paymentType,
            _paymentMetadata,
              _paymentCanisterId
          );
          break;
        case "INFINITY":
          await InfinityUtils.MakePaymentInfinity(
            cbIndex,
            unityContext,
            _to,
            amt,
            _paymentType,
            _paymentMetadata,
              _paymentCanisterId
          );
          break;
        case "STOIC":
          await StoicUtils.MakePaymentStoic(
            cbIndex,
            unityContext,
            _to,
            amt,
            _paymentType,
            _paymentMetadata,
              _paymentCanisterId
          );
          break;
        case "PLUG":
          await PlugUtils.MakePaymentPlug(
            cbIndex,
            unityContext,
            _to,
            amt,
            _paymentType,
            _paymentMetadata,
              _paymentCanisterId
          );
          break;
        default:
          let data = {};
          data.cbIndex = cbIndex;
          data.error =
            "Please open the Pause Menu by pressing ESC key and go to the top right corner and press the button that says CONNECTED. Then press the Logout button in the top right. Then Log In to the game again with your wallet and try this again";
          unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
      }
    }
  );

  unityContext.on(
    "BurnNft",
    async function (cbIndex, walletType, deployerCanisterId, collectionCanisterId, nftIndex) {

/*      console.log("REACT WALLET TYPE: " + walletType);
      console.log("REACT DEPLOYER CANISTER ID: " + deployerCanisterId);
      console.log("REACT COLLECTION CANISTER ID: " + collectionCanisterId);
      console.log("REACT NFT INDEX: " + nftIndex);*/

      switch (walletType) {
        case "NFID":
          await NfidUtils.BurnNftNfid(
            cbIndex,
            unityContext,
            nftIndex,
            collectionCanisterId,
              deployerCanisterId
          );
          break;
        case "INFINITY":
          await InfinityUtils.BurnNftInfinity(
            cbIndex,
            unityContext,
            nftIndex,
            collectionCanisterId,
              deployerCanisterId
          );
          break;
        case "STOIC":
          await StoicUtils.BurnNftStoic(
            cbIndex,
            unityContext,
            nftIndex,
            collectionCanisterId,
              deployerCanisterId
          );
          break;
        case "PLUG":
          await PlugUtils.BurnNftPlug(
            cbIndex,
            unityContext,
            nftIndex,
            collectionCanisterId,
              deployerCanisterId
          );
          break;
        default:
          let data = {};
          data.cbIndex = cbIndex;
          data.error =
            "Please open the Pause Menu by pressing ESC key and go to the top right corner and press the button that says CONNECTED. Then press the Logout button in the top right. Then Log In to the game again with your wallet and try this again";
          unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
      }
    }
  );

  unityContext.on("GetServerAuthToken", async function (cbIndex, walletType) {
    switch (walletType) {
      case "NFID":
        await NfidUtils.GetServerAuthToken(cbIndex, unityContext);
        break;
      case "INFINITY":
        await InfinityUtils.GetServerAuthToken(cbIndex, unityContext);
        break;
      case "STOIC":
        await StoicUtils.GetServerAuthToken(cbIndex, unityContext);
        break;
      case "PLUG":
        await PlugUtils.GetServerAuthToken(cbIndex, unityContext);
        break;
      default:
        let data = {};
        data.cbIndex = cbIndex;
        data.error =
          "Please open the Pause Menu by pressing ESC key and go to the top right corner and press the button that says CONNECTED. Then press the Logout button in the top right. Then Log In to the game again with your wallet and try this again";
        unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
    }
  });

  unityContext.on("ToggleFullscreen", function () {
    fullscreen = !fullscreen;
    unityContext.setFullscreen(fullscreen);
  });

  // WE'RE NO LONGER USING METAMASK
  unityContext.on("GetMetamaskNfts", async function (cbIndex) {
    await MetamaskUtils.GetMetamaskNfts(cbIndex, unityContext);
  });

  unityContext.on("RequestMetamaskConnect", async function (cbIndex) {
    await MetamaskUtils.RequestMetamaskConnect(cbIndex, unityContext);
  });
}
