import { Actor, HttpAgent } from "@dfinity/agent";
import { AuthClient } from "@dfinity/auth-client";
import { AccountIdentifier } from "@dfinity/nns";
import { requestTransfer, RequestTransferParams } from "@nfid/wallet";
import { Principal } from "@dfinity/principal";

let identity;

export async function MakeIcrcPaymentNfid(cbIndex, unityContext, _to, _amt, _paymentType, _paymentMetadata, _tokenCanisterId, paymentCanisterId) {
    let data = {};
    data.cbIndex = cbIndex;

    try {

        const APPLICATION_NAME = "PLETHORA";
        const APPLICATION_LOGO_URL = "https://superfind.io/logo37.png"; // change to plethora.zone
        const APP_META = `applicationName=RequestTransfer&applicationLogo=${APPLICATION_LOGO_URL}`;
        const AUTH_PATH = "/authenticate/?applicationName=" + APPLICATION_NAME + "&applicationLogo=" + APPLICATION_LOGO_URL + "#authorize";
        const NFID_AUTH_URL = "https://nfid.one" + AUTH_PATH;
        const NFID_ORIGIN = "https://nfid.one";
        const REQ_TRANSFER = "wallet/request-transfer";

        if (identity == null) {
            const authClient = await AuthClient.create();
            await new Promise((resolve, reject) => {
                authClient.login({
                    identityProvider: NFID_AUTH_URL,
                    windowOpenerFeatures:
                        `left=${window.screen.width / 2 - 525 / 2}, ` +
                        `top=${window.screen.height / 2 - 705 / 2},` +
                        `toolbar=0,location=0,menubar=0,width=525,height=705`,
                    derivationOrigin: "https://qyomk-5qaaa-aaaai-ab7ga-cai.ic0.app",
                    // derivationOrigin: "https://localhost:3000/",
                    onSuccess: resolve,
                    onError: reject,
                });
            });

            identity = authClient.getIdentity();
        }
        const paymentidlFactory = ({ IDL }) => {
            const HttpHeader = IDL.Record({ 'value': IDL.Text, 'name': IDL.Text });
            const CanisterHttpResponsePayload = IDL.Record({
                'status': IDL.Nat,
                'body': IDL.Vec(IDL.Nat8),
                'headers': IDL.Vec(HttpHeader),
            });
            const TransformArgs = IDL.Record({
                'context': IDL.Vec(IDL.Nat8),
                'response': CanisterHttpResponsePayload,
            });
            const Response = IDL.Variant({
                'Err': IDL.Text,
                'Success': IDL.Opt(IDL.Text),
            });
            const TxIndex = IDL.Nat;
            const Balance = IDL.Nat;
            return IDL.Service({
                'cycleBalance': IDL.Func([], [IDL.Nat], ['query']),
                'getAID': IDL.Func([IDL.Text], [IDL.Text], []),
                'getCaller': IDL.Func([], [IDL.Text], []),
                'transform': IDL.Func(
                    [TransformArgs],
                    [CanisterHttpResponsePayload],
                    ['query'],
                ),
                'update_assets': IDL.Func(
                    [IDL.Nat64, IDL.Text, IDL.Text, IDL.Nat64, IDL.Text, IDL.Text],
                    [Response],
                    [],
                ),
                'update_assets_icrc': IDL.Func(
                    [TxIndex, IDL.Text, IDL.Text, Balance, IDL.Text, IDL.Text, IDL.Text],
                    [Response],
                    [],
                ),
            });
        };

        const idlFactory = ({ IDL }) => {
            const Balance = IDL.Nat;
            const Timestamp = IDL.Nat64;
            const AdvancedSettings = IDL.Record({
                'permitted_drift': Timestamp,
                'burned_tokens': Balance,
                'transaction_window': Timestamp,
            });
            const Subaccount = IDL.Vec(IDL.Nat8);
            const Account = IDL.Record({
                'owner': IDL.Principal,
                'subaccount': IDL.Opt(Subaccount),
            });
            const TokenInitArgs = IDL.Record({
                'fee': Balance,
                'advanced_settings': IDL.Opt(AdvancedSettings),
                'decimals': IDL.Nat8,
                'minting_account': IDL.Opt(Account),
                'name': IDL.Text,
                'initial_balances': IDL.Vec(IDL.Tuple(Account, Balance)),
                'min_burn_amount': Balance,
                'max_supply': Balance,
                'symbol': IDL.Text,
            });
            const BurnArgs = IDL.Record({
                'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
                'from_subaccount': IDL.Opt(Subaccount),
                'created_at_time': IDL.Opt(IDL.Nat64),
                'amount': Balance,
            });
            const TxIndex = IDL.Nat;
            const TransferError = IDL.Variant({
                'GenericError': IDL.Record({
                    'message': IDL.Text,
                    'error_code': IDL.Nat,
                }),
                'TemporarilyUnavailable': IDL.Null,
                'BadBurn': IDL.Record({ 'min_burn_amount': Balance }),
                'Duplicate': IDL.Record({ 'duplicate_of': TxIndex }),
                'BadFee': IDL.Record({ 'expected_fee': Balance }),
                'CreatedInFuture': IDL.Record({ 'ledger_time': Timestamp }),
                'TooOld': IDL.Null,
                'InsufficientFunds': IDL.Record({ 'balance': Balance }),
            });
            const TransferResult = IDL.Variant({ 'Ok': TxIndex, 'Err': TransferError });
            const TxIndex__1 = IDL.Nat;
            const Burn = IDL.Record({
                'from': Account,
                'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
                'created_at_time': IDL.Opt(IDL.Nat64),
                'amount': Balance,
            });
            const Mint__1 = IDL.Record({
                'to': Account,
                'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
                'created_at_time': IDL.Opt(IDL.Nat64),
                'amount': Balance,
            });
            const Transfer = IDL.Record({
                'to': Account,
                'fee': IDL.Opt(Balance),
                'from': Account,
                'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
                'created_at_time': IDL.Opt(IDL.Nat64),
                'amount': Balance,
            });
            const Transaction__1 = IDL.Record({
                'burn': IDL.Opt(Burn),
                'kind': IDL.Text,
                'mint': IDL.Opt(Mint__1),
                'timestamp': Timestamp,
                'index': TxIndex,
                'transfer': IDL.Opt(Transfer),
            });
            const GetTransactionsRequest = IDL.Record({
                'start': TxIndex,
                'length': IDL.Nat,
            });
            const Transaction = IDL.Record({
                'burn': IDL.Opt(Burn),
                'kind': IDL.Text,
                'mint': IDL.Opt(Mint__1),
                'timestamp': Timestamp,
                'index': TxIndex,
                'transfer': IDL.Opt(Transfer),
            });
            const GetTransactionsRequest__1 = IDL.Record({
                'start': TxIndex,
                'length': IDL.Nat,
            });
            const TransactionRange = IDL.Record({
                'transactions': IDL.Vec(Transaction),
            });
            const QueryArchiveFn = IDL.Func(
                [GetTransactionsRequest__1],
                [TransactionRange],
                ['query'],
            );
            const ArchivedTransaction = IDL.Record({
                'callback': QueryArchiveFn,
                'start': TxIndex,
                'length': IDL.Nat,
            });
            const GetTransactionsResponse = IDL.Record({
                'first_index': TxIndex,
                'log_length': IDL.Nat,
                'transactions': IDL.Vec(Transaction),
                'archived_transactions': IDL.Vec(ArchivedTransaction),
            });
            const Account__1 = IDL.Record({
                'owner': IDL.Principal,
                'subaccount': IDL.Opt(Subaccount),
            });
            const Balance__1 = IDL.Nat;
            const Value = IDL.Variant({
                'Int': IDL.Int,
                'Nat': IDL.Nat,
                'Blob': IDL.Vec(IDL.Nat8),
                'Text': IDL.Text,
            });
            const MetaDatum = IDL.Tuple(IDL.Text, Value);
            const SupportedStandard = IDL.Record({ 'url': IDL.Text, 'name': IDL.Text });
            const TransferArgs = IDL.Record({
                'to': Account,
                'fee': IDL.Opt(Balance),
                'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
                'from_subaccount': IDL.Opt(Subaccount),
                'created_at_time': IDL.Opt(IDL.Nat64),
                'amount': Balance,
            });
            const Mint = IDL.Record({
                'to': Account,
                'memo': IDL.Opt(IDL.Vec(IDL.Nat8)),
                'created_at_time': IDL.Opt(IDL.Nat64),
                'amount': Balance,
            });
            const Token = IDL.Service({
                'burn': IDL.Func([BurnArgs], [TransferResult], []),
                'deposit_cycles': IDL.Func([], [], []),
                'get_transaction': IDL.Func([TxIndex__1], [IDL.Opt(Transaction__1)], []),
                'get_transactions': IDL.Func(
                    [GetTransactionsRequest],
                    [GetTransactionsResponse],
                    ['query'],
                ),
                'icrc1_balance_of': IDL.Func([Account__1], [Balance__1], ['query']),
                'icrc1_decimals': IDL.Func([], [IDL.Nat8], ['query']),
                'icrc1_fee': IDL.Func([], [Balance__1], ['query']),
                'icrc1_metadata': IDL.Func([], [IDL.Vec(MetaDatum)], ['query']),
                'icrc1_minting_account': IDL.Func([], [IDL.Opt(Account__1)], ['query']),
                'icrc1_name': IDL.Func([], [IDL.Text], ['query']),
                'icrc1_supported_standards': IDL.Func(
                    [],
                    [IDL.Vec(SupportedStandard)],
                    ['query'],
                ),
                'icrc1_symbol': IDL.Func([], [IDL.Text], ['query']),
                'icrc1_total_supply': IDL.Func([], [Balance__1], ['query']),
                'icrc1_transfer': IDL.Func([TransferArgs], [TransferResult], []),
                'mint': IDL.Func([Mint], [TransferResult], []),
            });
            return Token;
        };

        const agent = new HttpAgent({
            identity: identity,
            host: "https://ic0.app/",
        });

        const token = Actor.createActor(idlFactory, {
            agent,
            canisterId: _tokenCanisterId,
        });

        // Transfer tokens to desired address
        const _req = {
            to: {
                // owner : Principal.fromText(_to),
                owner: Principal.from(_to),
                subaccount: [],
            },
            fee: [BigInt(10)],
            memo: [],
            from_subaccount: [],
            created_at_time: [],
            amount: BigInt(_amt),
        };
        const _from = identity.getPrincipal().toText()
        const result = await token.icrc1_transfer(_req);
        if (result.Ok === undefined) {
            throw result.Err;
        } else {
            //asset update in DB
            const actor = Actor.createActor(paymentidlFactory, {
                agent,
                canisterId: paymentCanisterId,
            });
            const response = await actor.update_assets_icrc(BigInt(result.Ok), String(_to), String(_from), BigInt(_amt), String(_paymentType), String(_paymentMetadata), String(_tokenCanisterId));
            console.log('Purchase Result: ', response);

            if (response.Err != null) {
                throw new Error("Error in payment canister. Please contact us in Discord to troubleshoot the issue. Error=" + response.Err);
            }

            data.result = response.Success[0];
            unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
        }
    } catch (e) {
        console.error(e);
        data.error = e.message;
        if (e.InsufficientFunds) {
            data.error = "Insufficient Funds";
        }
        unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
    }
}

export async function MakePaymentNfid(cbIndex, unityContext, _to, _amt, _paymentType, _paymentMetadata, paymentCanisterId) {
    let data = {};
    data.cbIndex = cbIndex;

    try {

        const APPLICATION_NAME = "PLETHORA";
        const APPLICATION_LOGO_URL = "https://superfind.io/logo37.png"; // change to plethora.zone
        const APP_META = `applicationName=RequestTransfer&applicationLogo=${APPLICATION_LOGO_URL}`;
        const AUTH_PATH = "/authenticate/?applicationName=" + APPLICATION_NAME + "&applicationLogo=" + APPLICATION_LOGO_URL + "#authorize";
        const NFID_AUTH_URL = "https://nfid.one" + AUTH_PATH;
        const NFID_ORIGIN = "https://nfid.one";
        const REQ_TRANSFER = "wallet/request-transfer";

        const NNS_LEDGER_CID = 'ryjl3-tyaaa-aaaaa-aaaba-cai';

        if (identity == null) {
            const authClient = await AuthClient.create();
            await new Promise((resolve, reject) => {
                authClient.login({
                    identityProvider: NFID_AUTH_URL,
                    windowOpenerFeatures:
                        `left=${window.screen.width / 2 - 525 / 2}, ` +
                        `top=${window.screen.height / 2 - 705 / 2},` +
                        `toolbar=0,location=0,menubar=0,width=525,height=705`,
                    derivationOrigin: "https://qyomk-5qaaa-aaaai-ab7ga-cai.ic0.app",
                    // derivationOrigin: "https://localhost:3000/",
                    onSuccess: resolve,
                    onError: reject,
                });
            });

            identity = authClient.getIdentity();
        }

        const idlFactory = ({ IDL }) => {
            const HttpHeader = IDL.Record({ 'value': IDL.Text, 'name': IDL.Text });
            const CanisterHttpResponsePayload = IDL.Record({
                'status': IDL.Nat,
                'body': IDL.Vec(IDL.Nat8),
                'headers': IDL.Vec(HttpHeader),
            });
            const TransformArgs = IDL.Record({
                'context': IDL.Vec(IDL.Nat8),
                'response': CanisterHttpResponsePayload,
            });
            const Response = IDL.Variant({
                'Err': IDL.Text,
                'Success': IDL.Opt(IDL.Text),
            });
            return IDL.Service({
                'cycleBalance': IDL.Func([], [IDL.Nat], ['query']),
                'getAID': IDL.Func([IDL.Text], [IDL.Text], []),
                'getCaller': IDL.Func([], [IDL.Text], []),
                'transform': IDL.Func(
                    [TransformArgs],
                    [CanisterHttpResponsePayload],
                    ['query'],
                ),
                'update_assets': IDL.Func(
                    [IDL.Nat64, IDL.Text, IDL.Text, IDL.Nat64, IDL.Text, IDL.Text],
                    [Response],
                    [],
                ),
            });
        };

        const ledgerIdlFactory = ({ IDL }) => {
            const AccountIdentifier = IDL.Vec(IDL.Nat8);
            const AccountBalanceArgs = IDL.Record({ 'account': AccountIdentifier });
            const Tokens = IDL.Record({ 'e8s': IDL.Nat64 });
            const Archive = IDL.Record({ 'canister_id': IDL.Principal });
            const Archives = IDL.Record({ 'archives': IDL.Vec(Archive) });
            const BlockIndex = IDL.Nat64;
            const GetBlocksArgs = IDL.Record({
                'start': BlockIndex,
                'length': IDL.Nat64,
            });
            const Memo = IDL.Nat64;
            const Operation = IDL.Variant({
                'Burn': IDL.Record({ 'from': AccountIdentifier, 'amount': Tokens }),
                'Mint': IDL.Record({ 'to': AccountIdentifier, 'amount': Tokens }),
                'Transfer': IDL.Record({
                    'to': AccountIdentifier,
                    'fee': Tokens,
                    'from': AccountIdentifier,
                    'amount': Tokens,
                }),
            });
            const TimeStamp = IDL.Record({ 'timestamp_nanos': IDL.Nat64 });
            const Transaction = IDL.Record({
                'memo': Memo,
                'operation': IDL.Opt(Operation),
                'created_at_time': TimeStamp,
            });
            const Block = IDL.Record({
                'transaction': Transaction,
                'timestamp': TimeStamp,
                'parent_hash': IDL.Opt(IDL.Vec(IDL.Nat8)),
            });
            const BlockRange = IDL.Record({ 'blocks': IDL.Vec(Block) });
            const QueryArchiveError = IDL.Variant({
                'BadFirstBlockIndex': IDL.Record({
                    'requested_index': BlockIndex,
                    'first_valid_index': BlockIndex,
                }),
                'Other': IDL.Record({
                    'error_message': IDL.Text,
                    'error_code': IDL.Nat64,
                }),
            });
            const QueryArchiveResult = IDL.Variant({
                'Ok': BlockRange,
                'Err': QueryArchiveError,
            });
            const QueryArchiveFn = IDL.Func(
                [GetBlocksArgs],
                [QueryArchiveResult],
                ['query'],
            );
            const QueryBlocksResponse = IDL.Record({
                'certificate': IDL.Opt(IDL.Vec(IDL.Nat8)),
                'blocks': IDL.Vec(Block),
                'chain_length': IDL.Nat64,
                'first_block_index': BlockIndex,
                'archived_blocks': IDL.Vec(
                    IDL.Record({
                        'callback': QueryArchiveFn,
                        'start': BlockIndex,
                        'length': IDL.Nat64,
                    })
                ),
            });
            const SubAccount = IDL.Vec(IDL.Nat8);
            const TransferArgs = IDL.Record({
                'to': AccountIdentifier,
                'fee': Tokens,
                'memo': Memo,
                'from_subaccount': IDL.Opt(SubAccount),
                'created_at_time': IDL.Opt(TimeStamp),
                'amount': Tokens,
            });
            const TransferError = IDL.Variant({
                'TxTooOld': IDL.Record({ 'allowed_window_nanos': IDL.Nat64 }),
                'BadFee': IDL.Record({ 'expected_fee': Tokens }),
                'TxDuplicate': IDL.Record({ 'duplicate_of': BlockIndex }),
                'TxCreatedInFuture': IDL.Null,
                'InsufficientFunds': IDL.Record({ 'balance': Tokens }),
            });
            const TransferResult = IDL.Variant({
                'Ok': BlockIndex,
                'Err': TransferError,
            });
            const TransferFeeArg = IDL.Record({});
            const TransferFee = IDL.Record({ 'transfer_fee': Tokens });
            return IDL.Service({
                'transfer': IDL.Func([TransferArgs], [TransferResult], []),
            });
        };

        const agent = new HttpAgent({
            identity: identity,
            host: "https://ic0.app/",
        });

        const actor = Actor.createActor(idlFactory, {
            agent,
            canisterId: paymentCanisterId,
        });

        const ledger = Actor.createActor(ledgerIdlFactory, {
            agent,
            canisterId: NNS_LEDGER_CID,
        });

        const _from = identity.getPrincipal().toText()
        // console.log(_from);
        const accountID = await actor.getAID(_to);
        // console.log(accountID);

        //Amount Transfer Request
        const req = {
            to: (AccountIdentifier.fromHex(accountID)).toUint8Array(),
            fee: {
                e8s: BigInt(10000),
            },
            amount: {
                e8s: BigInt(_amt),
            },
            memo: BigInt(0),
            created_at_time: [],
            from_subaccount: [],
        };

        //blockHeight
        const height = await ledger.transfer(req);
        if (height.Ok === undefined) {
            throw height.Err;
        } else {
            //asset update in DB
            // console.log(height.Ok);
            const response = await actor.update_assets(BigInt(height.Ok), String(_to), String(_from), BigInt(_amt), String(_paymentType), String(_paymentMetadata));
            console.log('Purchase Result: ', response);

            if (response.Err != null) {
                throw new Error("Error in payment canister. Please contact us in Discord to troubleshoot the issue. Error=" + response.Err);
            }

            data.result = response.Success[0];
            unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
        }
    } catch (e) {
        console.error(e);
        data.error = e.message;
        if (e.InsufficientFunds) {
            data.error = "Insufficient Funds";
        }
        unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
    }
}

export async function BurnNftNfid(cbIndex, unityContext, nftIndex, collectionCanisterId, deployerCanisterId) {
    let data = {};
    data.cbIndex = cbIndex;

    try {

        const APPLICATION_NAME = "PLETHORA";
        const APPLICATION_LOGO_URL = "https://superfind.io/logo37.png"; // change to plethora.zone
        const APP_META = `applicationName=RequestTransfer&applicationLogo=${APPLICATION_LOGO_URL}`;
        const AUTH_PATH = "/authenticate/?applicationName=" + APPLICATION_NAME + "&applicationLogo=" + APPLICATION_LOGO_URL + "#authorize";
        const NFID_AUTH_URL = "https://nfid.one" + AUTH_PATH;
        const NFID_ORIGIN = "https://nfid.one";
        const REQ_TRANSFER = "wallet/request-transfer";

        if (identity == null) {
            const authClient = await AuthClient.create();
            await new Promise((resolve, reject) => {
                authClient.login({
                    identityProvider: NFID_AUTH_URL,
                    windowOpenerFeatures:
                        `left=${window.screen.width / 2 - 525 / 2}, ` +
                        `top=${window.screen.height / 2 - 705 / 2},` +
                        `toolbar=0,location=0,menubar=0,width=525,height=705`,
                    derivationOrigin: "https://qyomk-5qaaa-aaaai-ab7ga-cai.ic0.app",
                    onSuccess: resolve,
                    onError: reject,
                });
            });

            identity = authClient.getIdentity();
        }

        const idlFactory = ({ IDL }) => {
            const TokenIndex = IDL.Nat32;
            const AccountIdentifier = IDL.Text;
            const TokenIdentifier__1 = IDL.Text;
            const CommonError = IDL.Variant({
                'InvalidToken': TokenIdentifier__1,
                'Other': IDL.Text,
            });
            const Result_1 = IDL.Variant({
                'ok': IDL.Opt(IDL.Text),
                'err': CommonError,
            });
            const AssetHandle = IDL.Text;
            const Result = IDL.Variant({ 'ok': IDL.Null, 'err': CommonError });
            const TokenIdentifier = IDL.Text;
            const ICHttpHeader = IDL.Record({ 'value': IDL.Text, 'name': IDL.Text });
            const ICCanisterHttpResponsePayload = IDL.Record({
                'status': IDL.Nat,
                'body': IDL.Vec(IDL.Nat8),
                'headers': IDL.Vec(ICHttpHeader),
            });
            const ICTransformArgs = IDL.Record({
                'context': IDL.Vec(IDL.Nat8),
                'response': ICCanisterHttpResponsePayload,
            });
            return IDL.Service({
                'airdrop_to_addresses': IDL.Func(
                    [IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Bool, IDL.Int],
                    [IDL.Vec(TokenIndex)],
                    [],
                ),
                'batch_mint_to_address': IDL.Func(
                    [
                        IDL.Text,
                        AccountIdentifier,
                        IDL.Text,
                        IDL.Text,
                        IDL.Text,
                        IDL.Nat32,
                        IDL.Int,
                    ],
                    [IDL.Vec(TokenIndex)],
                    [],
                ),
                'burnNft': IDL.Func(
                    [IDL.Text, TokenIndex, AccountIdentifier],
                    [Result_1],
                    [],
                ),
                'burnNfts': IDL.Func(
                    [IDL.Text, TokenIndex, TokenIndex, AssetHandle],
                    [],
                    [],
                ),
                'clear_collection_registry': IDL.Func([], [], []),
                'create_collection': IDL.Func(
                    [IDL.Text, IDL.Text, IDL.Text, IDL.Nat64],
                    [IDL.Text],
                    [],
                ),
                'cycleBalance': IDL.Func([], [IDL.Nat], ['query']),
                'external_burn': IDL.Func([IDL.Text, TokenIndex], [Result], []),
                'getAID': IDL.Func([], [AccountIdentifier], []),
                'getCollectionMetadata': IDL.Func([IDL.Text], [IDL.Text, IDL.Text], []),
                'getCollections': IDL.Func([], [IDL.Vec(IDL.Text)], ['query']),
                'getOwner': IDL.Func([IDL.Text], [IDL.Text], ['query']),
                'getRegistry': IDL.Func([IDL.Text], [IDL.Vec(IDL.Text)], []),
                'getTokenIdentifier': IDL.Func(
                    [IDL.Text, TokenIndex],
                    [TokenIdentifier],
                    [],
                ),
                'getTokenMetadata': IDL.Func([IDL.Text, TokenIndex], [IDL.Text], []),
                'getTokenUrl': IDL.Func([IDL.Text, TokenIndex], [IDL.Text], []),
                'getUserNfts': IDL.Func(
                    [IDL.Text, IDL.Text],
                    [IDL.Vec(IDL.Tuple(TokenIndex, IDL.Text))],
                    [],
                ),
                'kill_cron': IDL.Func([], [], []),
                'setMinter': IDL.Func([IDL.Text, IDL.Text], [], []),
                'transform': IDL.Func(
                    [ICTransformArgs],
                    [ICCanisterHttpResponsePayload],
                    ['query'],
                ),
                'uploadAsset': IDL.Func(
                    [IDL.Text, AssetHandle, IDL.Text, IDL.Text],
                    [],
                    [],
                ),
                'wallet_receive': IDL.Func([], [IDL.Nat], []),
            });
        };

        const agent = new HttpAgent({
            identity: identity,
            host: "https://ic0.app/",
        });

        const actor = Actor.createActor(idlFactory, {
            agent,
            canisterId: deployerCanisterId,
        });

        //Burn Nft and Notify Server
        const _from = await actor.getAID();
        const response = await actor.burnNft(String(collectionCanisterId), Number(nftIndex), String(_from));
        console.log('Burn Result: ', response);

        if (response.err != null) {
            throw new Error("Error while calling burn in nft canister. Please contact us in Discord to troubleshoot the issue. Error=" + response.err);
        }

        data.result = response.ok[0];
        unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
    } catch (e) {
        console.error(e);
        data.error = e.message;
        unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
    }
}

export async function GetServerAuthToken(cbIndex, unityContext) {
    let data = {};
    data.cbIndex = cbIndex;

    try {

        const APPLICATION_NAME = "PLETHORA";
        const APPLICATION_LOGO_URL = "https://superfind.io/logo37.png"; // change to plethora.zone
        const AUTH_PATH = "/authenticate/?applicationName=" + APPLICATION_NAME + "&applicationLogo=" + APPLICATION_LOGO_URL + "#authorize";
        const NFID_AUTH_URL = "https://nfid.one" + AUTH_PATH;

        const authCanisterId = 'zxrmm-bqaaa-aaaai-abn6q-cai'

        const authClient = await AuthClient.create();
        await new Promise((resolve, reject) => {
            authClient.login({
                identityProvider: NFID_AUTH_URL,
                windowOpenerFeatures:
                    `left=${window.screen.width / 2 - 525 / 2}, ` +
                    `top=${window.screen.height / 2 - 705 / 2},` +
                    `toolbar=0,location=0,menubar=0,width=525,height=705`,
                derivationOrigin: "https://qyomk-5qaaa-aaaai-ab7ga-cai.ic0.app",
                onSuccess: resolve,
                onError: reject,
            });
        });

        const idlFactory = ({ IDL }) => {
            return IDL.Service({
                'get_auth_token': IDL.Func([], [IDL.Text], [])
            });
        };

        identity = authClient.getIdentity(); // always get a new identity in this case bc this is the login

        const agent = new HttpAgent({
            identity: identity,
            host: "https://ic0.app/",
        });

        const actor = Actor.createActor(idlFactory, {
            agent,
            canisterId: authCanisterId,
        });

        const token = await actor.get_auth_token();
        console.log('Auth Token Nfid: ', token);
        data.result = token;
        unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
    } catch (e) {
        console.error(e);
        data.error = e.message;
        unityContext.send("ReactApi", "HandleCallback", JSON.stringify(data));
    }
}